@use 'sass:math';

@function pxToRem($size) {
  $remSize: math.div($size, 16);
  @return #{$remSize}rem;
}

/* Colors */
$black: #000000;
$white: #ffffff;
$error: #ff00fa; //this color is used to represent a misuse of content (ex. text on a bg that should not include text, based on style guide)

$mono: #121212;
$mono-1: #f7f7f8;
$mono-2: #ebeced;
$mono-3: #cbced1;
$mono-4: #747678;
$mono-5: #444546;

$button: #3C4E6D;
$button-hover: #2B3950;
$prime: #386cc6;
$prime-1: #fbf7e6;
$prime-2: #faf0c7;
$prime-3: #ffe67f;
$prime-4: #e2b500;
$prime-5: #b88e33;

$link: #cdb88a;
$link-dark: #b88e33;
$success: #3c9f84;
$success-hover: #456f6c;
$warning: #9C231F;
$warning-hover: #6a4545;
$muted: $mono-3;
$body: $mono;
$border: #9ea1a3;

/* Breakpoints */
$xs: 420px;
$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1200px;

/* Spacing Values */
$sp-1: 0.25rem;
$sp-2: 0.5rem;
$sp-3: 1rem;
$sp-4: 1.5rem;
$sp-5: 3rem;

/* Typography */
$font-body: "Open Sans", sans-serif;
$font-title: "Stratum", "Open Sans", sans-serif;
$font-size-base: 1rem;
$word-space: 0.1rem;
$normal: 400;
$bold: 600;
